'use strict';

const verifyReservation = function () {
    function hide() {
        const overlay = document.getElementsByClassName('verify-reservation-overlay')[0];
        if (overlay) {
            document.getElementsByTagName('body')[0].removeChild(overlay);
            document.getElementsByTagName('body')[0].removeChild(document.getElementsByClassName('verify-reservation-panel')[0]);
        }
    }
    function createTextBox(labelText, inputId) {
        const element = document.createElement('div');
        element.classList.add('form-group');
        const label = document.createElement('label');
        label.innerText = labelText;
        const input = document.createElement('input');
        input.type = 'date';
        input.classList.add('form-control');
        input.id = inputId;
        element.appendChild(label);
        element.appendChild(input);
        return element;
    }
    function createButton(buttonText, buttonClasses, buttonId) {
        const button = document.createElement('button');
        button.innerText = buttonText;
        button.classList.add('btn');
        for (let i = 0; i < buttonClasses.length; i++) {
            button.classList.add(buttonClasses[i]);
        }
        button.id = buttonId;
        return button;
    }
    return {
        /** Show the Verify Reservation panel */
        show: function (apiEndpoint, callback) {
            // Check if overlay already exists
            let overlay = document.getElementsByClassName('verify-reservation-overlay')[0];
            if (overlay) {
                return;
            }
            // Add the overlay (the black div that covers the entire screen)
            overlay = document.createElement('div');
            overlay.classList.add('verify-reservation-overlay');
            document.getElementsByTagName('body')[0].appendChild(overlay);
            // Add the main panel
            const panel = document.createElement('div');
            panel.classList.add('verify-reservation-panel');
            // Message panel
            const messagePanel = document.createElement('div');
            messagePanel.innerHTML = 'Access to this feature is reserved for guests. Please verify that you are a guest staying at the property.<br /><br />';
            // Error panel
            const errorPanel = document.createElement('div');
            errorPanel.classList.add('alert');
            errorPanel.classList.add('alert-danger');
            errorPanel.classList.add('hide');
            // Append all the children to the main panel
            panel.appendChild(messagePanel);
            panel.appendChild(errorPanel);
            panel.appendChild(createTextBox('Check-in Date', 'verifyReservationCheckin'));
            panel.appendChild(createTextBox('Check-out Date', 'verifyReservationCheckout'));
            panel.appendChild(createButton('Verify', ['btn-success','margin-right-sml'], 'verifyReservationButton'));
            panel.appendChild(createButton('Cancel', ['btn-default'], 'verifyReservationCancel'));
            document.getElementsByTagName('body')[0].appendChild(panel);
            // Event listeners
            document.getElementById('verifyReservationButton').addEventListener('click', function () {
                if (!errorPanel.classList.contains('hide')) {
                    errorPanel.classList.add('hide');
                    errorPanel.innerText = '';
                }
                const checkinDate = document.getElementById('verifyReservationCheckin').value;
                const checkoutDate = document.getElementById('verifyReservationCheckout').value;
                if (!(checkinDate && checkoutDate)) {
                    errorPanel.classList.remove('hide');
                    errorPanel.innerText = 'Please enter your check-in and check-out dates';
                }
                else {
                    document.getElementById('verifyReservationButton').setAttribute('disabled', 'true');
                    document.getElementById('verifyReservationCancel').setAttribute('disabled', 'true');
                    const params = new Proxy(new URLSearchParams(window.location.search), {
                        get: (searchParams, prop) => searchParams.get(prop),
                    });
                    const passcode = params.accessCode;
                    fetch(`${apiEndpoint}/api/Functions/ValidateMobileGuestQuick`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ passcode, checkinDate, checkoutDate })
                    })
                    .then(function (resp) {
                        if (resp.status === 200) {
                            resp.json().then(function (data) {
                                document.getElementById('verifyReservationButton').removeAttribute('disabled');
                                document.getElementById('verifyReservationCancel').removeAttribute('disabled');
                                callback({
                                    action: 'verify',
                                    uniqueGuestLink: data.uniqueGuestLink
                                });
                                hide();
                            });
                        }
                        else {
                            resp.json().then(function (data) {
                                errorPanel.classList.remove('hide');
                                errorPanel.innerText = data.message;
                                document.getElementById('verifyReservationButton').removeAttribute('disabled');
                                document.getElementById('verifyReservationCancel').removeAttribute('disabled');
                            });
                        }
                    });
                }
            });
            document.getElementById('verifyReservationCancel').addEventListener('click', function () {
                callback({ action: 'cancel' });
                hide();
            });
        }
    }
}();